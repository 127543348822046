import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { DocumentsTab } from 'components/DocumentsTabs/DocumentsTabs';
import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { USERS } from 'services/users/query';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT_INVOICE', label: 'Most recent', suffix: '(invoice date)' },
    { value: 'LEAST_RECENT_INVOICE', label: 'Least recent', suffix: '(invoice date)' },
];

export const Invoices = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation('documents');

    const { data, refetch } = useQuery(USERS, {
        variables: { isMember: true, first: PAGE_SIZE },
    });

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const page = searchParams.get('page') || '1';

        refetch({
            search: search,
            sort: sort,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    const openInvoices = (id: string) => {
        navigate(AppRoutes.memberInvoices.replace(':id', id));
    };

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'invoices']} />
            <DocumentsTab />
            <PageTitle>{t('invoicesTitle')}</PageTitle>

            <div className="flex gap-4 flex-wrap pb-4">
                <SearchFilter placeholder={t('searchPlaceholder')} />
                <SortFilter options={SORT} />
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('nameLabel')}</TableHead>
                        <TableHead>{t('memberSinceLabel')}</TableHead>
                        <TableHead>{t('lastInvoiceLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.users.edges.length > 0 &&
                        normalizeArray(data?.users).map((user: any) => (
                            <TableRow
                                key={user.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => openInvoices(user.id)}
                            >
                                <TableCell className="flex gap-2 items-center">
                                    <UserImage image={user.image} />
                                    {user.lastName || '-'}, {user.firstName || '-'}
                                </TableCell>
                                <TableCell>{dayjs(user.dateJoined).format('DD.MM.YYYY')}</TableCell>
                                <TableCell className="hidden md:table-cell">
                                    {user.lastInvoice ? dayjs(user?.lastInvoice?.createdAt).format('DD.MM.YYYY') : '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Pagination pageInfo={data?.users?.pageInfo} total={data?.users?.totalCount} />
        </div>
    );
};

const UserImage = ({ image }: any) => {
    return (
        <div className="bg-zinc-300 w-[32px] h-[32px] flex justify-center items-center">
            {image ? (
                <img src={`${image.url}?fit=facearea&facepad=2.5&w=32&h=32`} alt={image.alt} />
            ) : (
                <Icon name="user" className="text-zinc-50 h-[18px]" />
            )}
        </div>
    );
};
