import { Avatar } from 'components/Avatar/Avatar';
import { Card } from 'components/Card/Card';
import { InfoItem } from 'components/InfoItem/InfoItem';
import { Label } from 'components/Label/Label';
import { ShareholderBadge } from 'components/ShareholderBadge/ShareholderBadge';
import { UserSettings } from 'components/UserSettings/UserSettings';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'; // ES 2015
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

dayjs.extend(relativeTime);

export const MemberPreviewAvatar = () => {
    const { t } = useTranslation('member');
    const { user, getUser } = useUserStore(state => state);

    if (!user) return null;
    return (
        <div>
            <Card className="flex flex-col gap-8 relative pb-3">
                <div className="flex flex-col gap-4">
                    <div className="flex justify-between">
                        <Avatar image={user?.image} status={user?.status} />
                        {/* <Button size="smallIcon">
                            <Icon name="export" className="h-4" />
                        </Button> */}
                    </div>
                    <div>
                        <div className="text-3xl font-semibold flex gap-3">
                            {user?.lastName}, {user?.firstName}
                            {user?.isShareholder && <ShareholderBadge />}
                        </div>
                        {user?.status === 'SUSPENDED' && (
                            <div className="text-sm mt-2 text-orange-700 font-medium">
                                {t('suspendedInfo', {
                                    from: dayjs(user?.suspendedFrom).format('DD.MM.YYYY'),
                                    to: dayjs(user?.suspendedUntil).format('DD.MM.YYYY'),
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    <InfoItem icon="company">{user?.jobPosition}</InfoItem>
                    <InfoItem icon="cake">{dayjs(user?.dateOfBirth).fromNow(true)} old</InfoItem>
                    <InfoItem icon="phone">{user?.phoneNumber}</InfoItem>
                    <InfoItem icon="mail">{user?.email}</InfoItem>
                </div>
                <div className="bg-zinc-200 h-px w-[300px]" />
                <div className="flex flex-col gap-6">
                    <div>
                        <Label className="opacity-60">{t('userId')}</Label>
                        <div>{user?.memberId}</div>
                    </div>
                    <div>
                        <Label className="opacity-60">{t('accountType')}</Label>
                        <div>{user?.memberType ? t(user?.memberType) : t('INDIVIDUAL')}</div>
                    </div>
                </div>
                <div className="bg-zinc-200 h-px w-[300px]" />
                <div>
                    <UserSettings
                        actions={['BLOCK', 'DELETE', 'PASSWORD_RESET', 'SUSPEND', 'TERMINATE']}
                        user={user}
                        onChange={() => getUser(user?.id)}
                    />
                </div>
            </Card>
        </div>
    );
};
