import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { UserType } from 'types/user';

type DraftMembersModalProps = {
    isOpen: boolean;
    onClose: () => void;
    members: UserType[];
};
export const DraftMembersModal: React.FC<DraftMembersModalProps> = ({ isOpen, onClose, members }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('dashboard');

    const editMember = (id: string) => {
        navigate(AppRoutes.editMember.replace(':id', id));
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-6">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('draftMembersModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('draftMembersModalSubtitle')}</div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Table className="border">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>{t('nameLabel')}</TableHead>
                                    <TableHead />
                                </TableRow>
                            </TableHeader>
                            <TableBody className="hover:cursor-pointer">
                                {members?.length > 0 &&
                                    members.map((member: any) => (
                                        <TableRow key={member.id}>
                                            <TableCell className="flex gap-2 items-center w-full">
                                                <Avatar image={member.image} size={32} />
                                                {member.lastName || '-'}, {member.firstName || '-'}
                                            </TableCell>
                                            <TableCell className="w-4">
                                                <Button
                                                    variant="ghost"
                                                    className="flex gap-1 items-center text-rose-700"
                                                    onClick={() => editMember(member.id)}
                                                >
                                                    <Icon name="edit" className="h-4" />
                                                    <span className="underline">{t('editButton')}</span>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <div className="flex justify-end">
                            <Button variant="outline" onClick={onClose}>
                                {t('closeButton')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
