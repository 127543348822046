import { useQuery } from '@apollo/client';
import { Card, CardTitle } from 'components/Card/Card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

export const NewMembersList = () => {
    const { t } = useTranslation('dashboard');
    const { data } = useQuery(USERS, {
        variables: {
            isMember: true,
            first: 5,
            sort: 'MOST_RECENT',
        },
    });
    return (
        <Card className="border flex flex-col gap-6 flex-1">
            <div className="flex justify-between items-center">
                <CardTitle className="mb-0 text-base">{t('newMembersTitle')}</CardTitle>
                <span className="text-xs text-zinc-500">{t('last5Members')}</span>
            </div>
            <div>
                <Table className="border bg-white">
                    <TableHeader>
                        <TableRow>
                            <TableHead>{t('nameLabel')}</TableHead>
                            <TableHead>{t('entryDateLabel')}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {normalizeArray(data?.users)?.length > 0 &&
                            normalizeArray(data?.users).map((member: any) => (
                                <TableRow key={member.id}>
                                    <TableCell className="flex gap-2 items-center w-full">
                                        {member.lastName || '-'}, {member.firstName || '-'}
                                    </TableCell>
                                    <TableCell>{dayjs(member.dateJoined).format('DD.MM.YYYY')}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </Card>
    );
};
