import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Icon } from 'components/Icon/Icon';
import { PreviewHeader } from 'components/PreviewHeader/PreviewHeader';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'stores/user.store';

import { MemberPreviewAnniversaries } from './_components/MemberPreviewAnniversaries';
import { MemberPreviewAssistant } from './_components/MemberPreviewAssistant';
import { MemberPreviewAvatar } from './_components/MemberPreviewAvatar';
import { MemberPreviewbalance } from './_components/MemberPreviewBalance';
import { MemberPreviewFamily } from './_components/MemberPreviewFamily';
import { MemberPreviewPartner } from './_components/MemberPreviewPartner';
import { MemberPreviewPreferences } from './_components/MemberPreviewPreferences';

export const MemberPreview = () => {
    const user = useUserStore(state => state.user);
    const navigate = useNavigate();
    return (
        <>
            <PreviewHeader />
            <Breadcrumbs crumbs={['members', `${user?.lastName}, ${user?.firstName} `]} className="text-white" />
            <div className="text-white text-2xl mt-8 mb-4">
                <Icon name="back" onClick={() => navigate(-1)} className="hover:cursor-pointer" />
            </div>

            <div className="flex gap-4">
                <MemberPreviewAvatar />
                <div className="flex flex-col gap-6 flex-1">
                    <MemberPreviewbalance />
                    <MemberPreviewAnniversaries />
                    <MemberPreviewPreferences />
                    <MemberPreviewPartner />
                    <MemberPreviewFamily />
                    <MemberPreviewAssistant />
                </div>
            </div>
        </>
    );
};
