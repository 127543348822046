import { useMutation } from '@apollo/client';
import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Textarea } from 'components/Textarea/Textarea';
import { useToast } from 'components/Toast/use-toast';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MANUAL_BALANCE_EDIT } from 'services/users/mutation';

type EditBalanceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    member: any;
    onUpdate: () => void;
};

export const EditBalanceModal: React.FC<EditBalanceModalProps> = ({ isOpen, onClose, member, onUpdate }) => {
    const { t } = useTranslation('documents');
    const [editBalance] = useMutation(MANUAL_BALANCE_EDIT);
    const { toast } = useToast();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm();

    useEffect(() => {
        return () => reset();
    }, [onClose, reset]);

    const onSubmit = async (formData: any) => {
        formData.member = member.id;
        const resp = await editBalance({ variables: { input: formData } });
        if (resp.data?.manualBalanceEntryCreate?.balanceEntry?.id) {
            toast({
                title: t('balanceEditSuccess'),
                variant: 'success',
            });
            onUpdate();
        } else {
            failToast();
        }
        onClose();
    };

    const failToast = () => {
        toast({
            title: t('errorToastTitle'),
            description: t('errorToastMessage'),
            variant: 'warning',
        });
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-8">
                    <div>
                        <div className="text-lg font-semibold text-zinc-700 mb-2">{t('editBalanceModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('editBalanceModalSubtitle')}</div>
                    </div>
                    <div className="flex gap-2 items-center">
                        <Avatar image={member?.image} size={32} />
                        <div className="flex flex-col">
                            <div>
                                {member?.lastName}, {member?.firstName}
                            </div>
                            <div className="text-xs text-zinc-500">
                                <Balance balance={member?.balance} />
                            </div>
                        </div>
                    </div>
                    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Label>{t('amountInputLabel')}</Label>
                            <Input
                                {...register('amount', {
                                    required: t('balanceModalAmountError'),
                                })}
                                placeholder={t('amountInputPlaceholder')}
                                type="number"
                                step={0.01}
                                error={!!errors.balance}
                            />
                            <FormError error={errors.balance} />
                        </div>
                        <div>
                            <Label>{t('noteInputLabel')}</Label>
                            <Textarea {...register('note')} placeholder={t('noteInputPlaceholder')} />
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outline" type="button" onClick={onClose}>
                                {t('cancelModalButton')}
                            </Button>
                            <Button disabled={!watch('amount')} type="submit">
                                {t('confirmModalButton')}
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Portal>
    );
};
