import clsx from 'clsx';

import styles from './LanguageSwitcher.module.scss';

export const LanguageSwitcher = () => {
    const activeLanguage = localStorage.getItem('czrLanguage')?.toLowerCase() || 'en';

    const setLanguage = (lang: string) => {
        localStorage.setItem('czrLanguage', lang);
        window.location.reload();
    };
    return (
        <div className={styles.languageSwitcher}>
            <div
                className={clsx(styles.languageButton, activeLanguage === 'de' && styles.active)}
                onClick={() => setLanguage('de')}
            >
                DE
            </div>
            <div className={styles.spacer}></div>

            <div
                className={clsx(styles.languageButton, activeLanguage === 'en' && styles.active)}
                onClick={() => setLanguage('en')}
            >
                EN
            </div>
        </div>
    );
};
