import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { RoleFilter } from 'components/RoleFilter/RoleFilter';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Status } from 'components/Status/Status';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { cn, normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { USERS } from 'services/users/query';

const STATUS = ['ACTIVE', 'BLOCKED', 'DRAFT'];
const ROLE = ['ADMIN', 'EXECUTIVE', 'EMPLOYEE'];
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];

export const StaffList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data, loading, refetch } = useQuery(USERS, { variables: { isStaff: true } });
    const { t } = useTranslation('staff');

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const status = searchParams.get('status')?.split(',');
        const role = searchParams.get('role')?.split(',');
        const next = searchParams.get('next');
        const prev = searchParams.get('prev');

        refetch({ search: search, sort: sort, status: status, role: role, after: next, before: prev });
    }, [searchParams, refetch]);

    const previewStaff = (id: any) => {
        navigate(`${AppRoutes.staffProfile.replace(':id', id)}`);
    };

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'staff']} />
            <PageTitle>{t('title')}</PageTitle>

            <div className="flex justify-between pb-4 gap-4">
                <div className="flex gap-4 flex-wrap">
                    <SearchFilter placeholder={t('searchPlaceholder')} />
                    <StatusFilter options={STATUS} />
                    <RoleFilter options={ROLE} />
                    <SortFilter options={SORT} />
                </div>
                <Button asChild>
                    <Link to={AppRoutes.newStaff}>{t('addStaffButton')}</Link>
                </Button>
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('nameLabel')}</TableHead>
                        <TableHead>{t('roleLabel')}</TableHead>
                        <TableHead>{t('phoneLabel')}</TableHead>
                        <TableHead>{t('activeLabel')}</TableHead>
                        <TableHead>{t('statusLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.users.edges.length > 0 &&
                        normalizeArray(data?.users).map((user: any) => (
                            <TableRow
                                key={user.id}
                                onClick={() => previewStaff(user.id)}
                                className="text-zinc-700 hover:bg-rose-50"
                            >
                                <TableCell className="flex gap-2 items-center">
                                    <UserImage image={user.image} />
                                    {[user.lastName || '-', user.firstName].filter(Boolean).join(', ')}
                                </TableCell>
                                <TableCell className="capitalize">{user.staffRole?.toLowerCase() || '-'}</TableCell>
                                <TableCell>{user.phoneNumber || '-'}</TableCell>
                                <TableCell>{dayjs(user.dateJoined).format('DD MMM YYYY')}</TableCell>
                                <TableCell>
                                    <Status status={user.status} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.users.edges.length === 0 && <div className={'py-5 text-center text-zinc-700'}>{t('noResults')}</div>}
        </Container>
    );
};

const UserImage = ({ image }: any) => {
    return (
        <div className={cn(`bg-zinc-300 w-[32px] h-[32px] flex justify-center items-center`)}>
            {image ? (
                <img src={`${image.url}?fit=facearea&facepad=2.5&w=32&h=32`} alt={image.alt} />
            ) : (
                <Icon name="user" className="text-zinc-50 h-[18px]" />
            )}
        </div>
    );
};
